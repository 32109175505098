@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&family=Playfair+Display:wght@700&display=swap");
/* font-family: 'Playfair Display', serif; */
/* font-family: 'Montserrat', sans-serif; */

@font-face {
  font-family: 'Alako';
  src: url('../fonts/Alako-Bold.woff2') format('woff2'),
    url('../fonts/Alako-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}