::selection{ background: #0a2d71; color: #fff; }
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0 }
input[type="number"] { -moz-appearance: textfield; }

.errorMessage { color: red; font-size: 14px; }
.question-wrapper { min-height: 250px; }
.Toastify__toast{ border-radius: 5px!important; min-height: 55px!important; font-family: inherit!important; }
.Toastify__toast-body{ flex-basis: 70%; }

.ordered-list { line-height: 28px; list-style-type: none; counter-reset: item; }
.ordered-list > .ordered-list-item { line-height: 28px; display: table; counter-increment: item; margin-bottom: 0.6em; }
.ordered-list > .ordered-list-item:before { content: counters(item, ".") ". "; display: table-cell; padding-right: 0.6em; }
.ordered-list > .term-heading:before { font-weight: bold; }
.ordered-list-item .ordered-list > .ordered-list-item { margin: 0; }
.ordered-list-item .ordered-list > .ordered-list-item:before { content: counters(item, ".") " "; }
.unordered-list { list-style: disc outside; padding: 0 0 20px 15px; }
.unordered-list li { line-height: 28px; }

.para { font-size: 16px!important; font-weight: 400!important; }
.item-container { display: flex;flex-wrap: wrap; justify-content: center; gap: 5px; }
.flex-item { flex: 20%; max-width: 20%; margin: 10px 0; align-self: center; }
.flex-item img { vertical-align: middle; height: auto; width: 154px; margin: 4px; }
.about-us-banner { background: url("../images/banner-about-us.png") no-repeat center; height: 195px; margin: 30px 0; background-size: cover; }

@media (max-width: 1296px) {
  .about-us-banner { background-size: contain; }
}

@media (max-width:991px) {
  .flex-item{ flex: 25%; max-width: 25%; }
  .about-us-banner { margin: 0; }
}

@media (max-width:767px) {
  .flex-item{ flex: 33.33%; max-width: 33.33%; }
}

@media (max-width:575px) {
  .Toastify__toast{ min-height: 40px!important; font-size: 14px!important; margin: 10px; }
  .ordered-list, .unordered-list, .para, .unordered-list li, .ordered-list-item{ font-size: 14px!important; line-height: 22px!important; }
  .flex-item { flex: 50%; max-width: 50%; }
  .flex-item img { width: 130px; height: 70px; }
  .about-us-banner { height: 70px; }
}